<template>
  <v-card class="w-100" color="surfContainerHighest">
    <v-card-title class="bg-surface">
      <v-toolbar density="comfortable" color="surface">
        <v-toolbar-title :class="$vuetify.display.lgAndDown ? 'task-details-title w-50 pa-2 mr-4' : 'pa-2 w-50'">
          <h5 class="text-h5 text-onSurfaceVar font-weight-black task-title"> {{ task.title }}<v-tooltip
              activator="parent" location="bottom">{{ task.title }}</v-tooltip></h5>
        </v-toolbar-title>
        <!-- <div class="task-details-context mx-4">
                <v-chip variant="tonal" :color="socketConnected ? 'info' : 'warning'">
                    Activity Visibility : {{ socketConnected }}
                </v-chip>
            </div> -->

            <div class="w-25 mr-32">
          <v-chip class="task-details-title-chip" variant="tonal" color='primary'>
            <span class="text-body-2 font-weight-black ">#{{ task.taskIdentifier }}</span>
          </v-chip>
          <v-chip v-if="task.dueDate && currentStatusState?.name !== TaskStatusCategory.DONE"
            :color="getDueColor(task.dueDate!)" class="task-details-title-chip">
            <span class="text-body-2 font-weight-bold ">Due {{ dueDate }} </span>
          </v-chip>
          <v-chip v-else-if="currentStatusState?.name == TaskStatusCategory.DONE" color="success" label variant="flat"
            size="x-small">
            <span class="text-caption">Done</span>
          </v-chip>
          <v-chip :color="getPriorityColor(task.priority)" class="task-details-title-chip" variant="tonal">
            <div class="text-center">
              <span class="text-body-2 font-weight-black "> {{ task.priority }} </span>
              <v-icon class="mx-2 font-weight-black">{{ getPriorityIcon(task.priority) }}</v-icon>
            </div>
          </v-chip>
          <v-chip class="task-details-title-chip" :color=selectedTaskQueue?.value.color>
            <span class="text-body-1 font-weight-black ">{{ selectedTaskQueue?.label }}</span>
          </v-chip>
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-btn color="primary" variant="elevated" v-bind="props" :loading="statusUpdateInProgress" elevation="3"
                rounded="false" class="mx-4">
                {{ currentStatusState?.name || 'Open' }} <v-icon class="ml-4" icon="mdi-chevron-down" />
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(statusState, index) in statusStates" :key="index">
                <v-list-item-title @click="handleTaskStatusUpdate(statusState)">{{ statusState.name
                  }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex justify-end align-center w-25">
          <v-dialog persistent width="650">
            <template v-slot:activator="{ props }">
              <v-tooltip text="Edit Task" location="top">
                <template v-slot:activator="{ props: tooltipProps }">
                  <v-btn v-bind="{ ...props, ...tooltipProps }" variant="tonal" color="primary" elevation="3" rounded
                    class="mx-2" size="x-small" :disabled="!!currentUserActionEvent" icon="mdi-pencil" />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card>
                <v-card-item class="my-2 px-0">
                  <add-task-form :requestor-input="requestorInput!" :assignee-input="assigneeInput!"
                    :task-queue-input="taskQueueInput!" @edit-task="editedTasDetails"
                    @add-task-closed="isActive.value = false" :taskDetails="task" :task-actors="taskActors" />
                </v-card-item>
              </v-card>
            </template>
          </v-dialog>
          <v-btn icon="mdi-close-thick" color="black" class="task-details-action" @click="handleDetailsClose"
            variant="tonal" rounded size="x-small" elevation="3" />
        </div>
      </v-toolbar>
      <patient-id-card class="px-2" v-if="patientInfo && isPatientProfileTaskView" :patient-info="patientInfo" />
    </v-card-title>
    <v-card-item class="pa-0">
      <v-banner lines="one" v-if="currentUserActionEvent" :class="`bg-${trackingCurrentActivity ? 'info' : 'grey'}`"
        density="comfortable">
        <template v-slot:prepend>
          <div class="d-flex align-center justify-center">
            <v-icon icon="mdi-clock" class="mr-2" />
            <span class="font-weight-bold text-h6">
              {{ `${actionTimeElapsed.minutes} mins` }} {{ `${actionTimeElapsed.seconds} sec` }}</span>
          </div>

        </template>
        <template v-slot:text>
          <div class="current-user-action-event-info-wrapper d-flex align-center justify-center">
            <span class="text-subtitle-1 font-weight-bold">
              {{ currentUserActionEvent.actionDefinition.description }}
            </span>
          </div>
        </template>
        <template v-slot:actions>
          <v-btn color="surface" variant="tonal" class="font-weight-bold ma-1" rounded="false" size="small"
            @click="toggleTaskActionsStopwatch">
            <template v-if="trackingCurrentActivity">
              <v-icon icon="mdi-timer-pause" class="text-white mx-2" size="x-large" />
              <span class="text-white">
                Pause activity
              </span>
            </template>
            <template v-else>
              <v-icon icon="mdi-timer-play" class="text-white  mx-2" size="x-large" />
              <span class="text-white ">
                Resume activity
              </span>
            </template>
          </v-btn>
        </template>
      </v-banner>
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col :cols="5" class="pa-2">
            <v-card flat color="surfContainer">
              <v-card-title class="bg-surface pa-2">

              </v-card-title>
              <v-card-item class="pa-0">
                <div class="task-details-wrapper">
                  <div class="task-detail-panel-wrapper">
                    <task-info :task-actors="taskActors" :task="task" :task-queue="taskQueue"
                      :add-comment-handler="addCommentHandler" :current-user="currentUser"
                      :task-action-definitions="availableActions" :is-paused="currentUserActionPaused"
                      :list-comments-loader="getDependencies.listComments"
                      :is-patient-profile-task-view="isPatientProfileTaskView"
                      :is-task-popup-closed="isTaskPopupClosed" />
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </v-col>
          <v-col cols="7" class="py-2" v-if="!isPatientProfileTaskView">
            <patient-monitor :compact-view="true" :patient-profile="patientProfile" :get-dependencies="getDependencies"
              :current-activity="currentUserActionEvent" @request-patient-monitor-exit="handleDetailsClose"
              :is-task-board-task-view="isTaskBoardTaskView" :vital-criteria-compact-view="true" />
          </v-col>
          <v-col cols="7" class="py-2" v-if="isPatientProfileTaskView">
            <PatientSnapshotInfo :patient-id="patientId" :is-edit-form="true" :snapshot-patient-info="getDependencies.snapshotPatientInfo"
            :patient-vital-config-loader="getDependencies.getPatientVitalsThresholdConfig" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="success" class="text-white" v-model="showEditSuccessMessage" location="top right">
    Task detail(s) edited successfully
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showEditSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <Loader :overlay="loading" />
  <!-- <VSonner position="top-center" /> -->
</template>

<script setup lang="ts">
import { IAssigneeInput, ICreateTaskInput, IRequestorInput, ITask, ITaskActionDefinition, ITaskQueue, ITaskQueueInput, ITaskQueueItem, IUpdatedTaskResponse, UpdateTaskInput, UpdateTaskStatusInput } from '@/interfaces/task.interface';
import { daysFromNow, minutesFromNowInt } from '@/helpers/date.helpers';
import { onMounted, defineProps, PropType, defineEmits, computed, ref, watch } from 'vue';
import { ITaskStatusState, ITaskActor, ITaskActionEvent } from '../../../../interfaces/task.interface';
import PatientMonitor from '@/components/patient/profile/PatientMonitor.vue';
import { IPatientInfo, IPatientProfileProps } from '@/interfaces/patient.interface';
import TaskInfo from '../../../common/kanban/TaskInfo.vue';
import { IPatientData } from '@/interfaces/Service.interface';
import { getPatientByPatientId } from '@/services/patient.service';
import { TaskPriority } from '@/enums/task.enum';
import { useEventBus } from '@vueuse/core';
import { taskActionEventBusKey, taskActionFromTaskPopupEventBusKey, taskTimerControlEventBusKey } from '@/events/bus-keys/task-events.bus-keys';
import { IUser } from '@/interfaces/user.interface';
import { useStopwatch } from "vue-timer-hook";
import moment from "moment";
import { forEach, includes, map, compact } from 'lodash';
import { useSocketStore } from '@/store/modules/socket.store';
import { VSonner, toast } from 'vuetify-sonner'
import { CommentInput, IComment } from '@/interfaces/comment.interface';
import { useQueryClient } from '@tanstack/vue-query';
import AddTaskForm from '../../../common/kanban/AddTaskForm.vue';
import { AppActivityTrackerState } from '@/enums/app-activity-tracker.enum';
import { patientTaskTimeTrackerBusKey, newActivityStartedEventBusKey } from '@/events/bus-keys/time-tracking-events.bus-keys';
import { noteTaskListEventBusKey } from '@/events/bus-keys/note-task-events.bus-keys';
import { TaskStatusCategory } from '@/enums/task.enum';
import Loader from '../../../common/Loader.vue';
import PatientIdCard from '@/components/patient/profile/PatientIdCard.vue';
import PatientSnapshotInfo from '../../snapshot/PatientSnapshotInfo.vue';

const props = defineProps({
  task: {
    type: Object as PropType<ITask>,
    required: true
  },
  statusStates: {
    type: Array as PropType<ITaskStatusState[]>,
    required: true
  },
  taskActors: {
    type: Array as PropType<ITaskActor[]>,
    required: true
  },
  taskStatusUpdateHandler: {
    type: Function as PropType<(input: UpdateTaskStatusInput) => Promise<ITask>>,
    required: true
  },
  getDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
  requestorInfo: {
    type: Object as PropType<IPatientInfo>,
    required: false
  },
  taskQueue: {
    type: Object as PropType<ITaskQueue>,
    required: false,
  },
  currentUser: {
    type: Object as PropType<IUser>,
    required: true,
  },
  taskActionDefinitions: {
    type: Object as PropType<ITaskActionDefinition[]>,
    default: [] as ITaskActionDefinition[],
  },
  addCommentHandler: {
    type: Function as PropType<(createCommentInput: CommentInput) => Promise<IComment>>,
    required: true,
  },
  maxConcurrentToasts: {
    type: Number,
    default: 5
  },
  isPatientProfileTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  isTaskBoardTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  taskQueues: {
    type: Object as PropType<ITaskQueueItem[]>,
    required: true,
  },
  submitActionDelegate: {
    type: Function as PropType<(createTaskInput: ICreateTaskInput) => Promise<ITask>>,
    required: false,
  },
  requestorInput: {
    type: Object as PropType<IRequestorInput>,
    required: false,
  },
  assigneeInput: {
    type: Object as PropType<IAssigneeInput>,
    required: false,
  },
  taskQueueInput: {
    type: Object as PropType<ITaskQueueInput>,
    required: false,
  },
  editTaskDetail: {
    type: Function as PropType<(updateInput: UpdateTaskInput) => Promise<IUpdatedTaskResponse>>,
    required: false,
  },
  isWidgetView: {
    type: Boolean,
    default: false,
    required: false,
  },
  patientInfo: {
    type: Object as PropType<IPatientInfo>,
    required: false,
  }
});

const task = ref(props.task);
const { statusStates, taskStatusUpdateHandler, patientId, requestorInfo, currentUser, taskActionDefinitions, maxConcurrentToasts, getDependencies, taskQueues, editTaskDetail, isPatientProfileTaskView } = props
const taskActivityEventBus = useEventBus(taskActionEventBusKey);
const taskTimerControlEventBus = useEventBus(taskTimerControlEventBusKey);
const noteTaskActionEventBus = useEventBus(noteTaskListEventBusKey);
const taskActionFromTaskPopupEventBus = useEventBus(taskActionFromTaskPopupEventBusKey)



const patientTaskTrackerEventBus = useEventBus(patientTaskTimeTrackerBusKey);
const newActivityStartedEventBus = useEventBus(newActivityStartedEventBusKey);

const selectedStatusState = ref<ITaskStatusState>();
const statusUpdateInProgress = ref(false);
const patientProfile = ref({ patientId, patientInfoLoader: getPatientByPatientId, patientInfo: requestorInfo } as IPatientProfileProps)
const currentUserActionEvent = ref<ITaskActionEvent | undefined>();
const currentUserActionPaused = ref(false);
const otherUsersActionEvents = ref<ITaskActionEvent[]>([]);
const taskActionStopwatch = useStopwatch(0, false);
const socketStore = useSocketStore();
const socketConnected = ref(socketStore.connected);
const updatedStatusTask = ref<ITaskStatusState>();
const queryClient = useQueryClient();
const isTaskPopupClosed = ref(false);
const selectedTaskQueue = ref<ITaskQueueItem>();
const showErrorMessage = ref(false);
const errorMessage = ref('');
const dueDate = ref<string | undefined>();
const loading = ref(false);
const isActivityTimePausedExplicity = ref(false);
const showEditSuccessMessage = ref(false);

socketStore.$subscribe((mutation, state) => {
  socketConnected.value = state.connected;
});

const trackingCurrentActivity = computed(() => {
  return currentUserActionEvent.value?.actionState !== 'COMPLETE' && taskActionStopwatch.isRunning.value;
});

const currentStatusState = computed(() => {
  return selectedStatusState.value ? statusStates.find((statusState) => statusState.id === (updatedStatusTask.value?.taskStatusStateId ?? task.value.status.taskStatusStateId)) : statusStates[0];
});

const actionTimeElapsed = computed(() => {
  const timerReading = {
    days: taskActionStopwatch.days.value,
    hours: taskActionStopwatch.hours.value,
    minutes: taskActionStopwatch.minutes.value,
    seconds: taskActionStopwatch.seconds.value,
  };
  const elapsedTime = moment.duration(timerReading);
  return {
    minutes: Math.floor(elapsedTime.asMinutes()),
    seconds: elapsedTime.seconds(),
    elapsedTime,
    timerReading
  };
});

const availableActions = computed(() => {
  const actionsInprogress = map(otherUsersActionEvents.value.filter((event) => event.actionState === 'ACTIVE'), (event) => event.actionDefinition.id);
  return taskActionDefinitions.filter((action) => !includes(actionsInprogress, action.id));
});


const emits = defineEmits(["taskDetailsClosed", "taskEditSuccess"]);

const isCurrentUser = (actor: IUser) => {
  return actor.userId === currentUser.userId;
}

const handleDetailsClose = () => {
  isTaskPopupClosed.value = true;
  emits('taskDetailsClosed', true);
}

const handleTaskStatusUpdate = async (statusState: ITaskStatusState) => {
  statusUpdateInProgress.value = true;
  const statusUpdateInput = {
    taskId: task.value.id,
    statusId: statusState.id
  } as UpdateTaskStatusInput;
  const response = await taskStatusUpdateHandler(statusUpdateInput);
  statusUpdateInProgress.value = false;
  updatedStatusTask.value = response?.status;
  noteTaskActionEventBus.emit('note-task-list-event-bus');
  queryClient.invalidateQueries({ queryKey: ['taskBoard'] })
  queryClient.invalidateQueries({ queryKey: ['myTasks'] })
}

const toggleTaskActionsStopwatch = () => {
  currentUserActionPaused.value = !currentUserActionPaused.value;
}

const getPriorityIcon = (priority: TaskPriority) => {
  switch (priority) {
    case TaskPriority.LOW:
      return 'mdi mdi-chevron-down';
    case TaskPriority.MEDIUM:
      return 'mdi mdi-equal';
    case TaskPriority.HIGH:
      return 'mdi mdi-chevron-up';
    case TaskPriority.CRITICAL:
      return 'mdi mdi-chevron-double-up';
  }
}

const getPriorityColor = (priority: TaskPriority) => {
  switch (priority) {
    case TaskPriority.LOW:
      return 'normal';
    case TaskPriority.MEDIUM:
      return 'moderate';
    case TaskPriority.HIGH:
      return 'high';
    case TaskPriority.CRITICAL:
      return 'critical';
  }
}


const getDueColor = (date: string) => {
  return minutesFromNowInt(date) < 0 ? 'error' : 'success';
}


const currentUserActionState = (actionState: string, userEvent: ITaskActionEvent) => {
  const stopWatchRunning = !taskActionStopwatch.isRunning.value;
  if (includes(['ACTIVE', 'RESUMED'], actionState)) {
    actionState === 'ACTIVE' && !stopWatchRunning ? taskActionStopwatch.reset() : taskActionStopwatch.start();
  } else {
    currentUserActionEvent.value = actionState === 'COMPLETE' ? undefined : userEvent;
    taskActionFromTaskPopupEventBus.emit(currentUserActionEvent.value);
    if (actionState === 'COMPLETE') {
      taskActionStopwatch.reset();
    } else {
      taskActionStopwatch.pause();
    }
  }
}

const displayOtherUserActivityToast = (event: ITaskActionEvent) => {
  const verbs = {
    'ACTIVE': 'started',
    'RESUMED': 'resumed',
    'PAUSED': 'paused',
    'COMPLETE': 'completed'
  };
  const title = `${event.actor.firstName} ${event.actor.lastName} ${verbs[event.actionState]} ${event.actionDefinition.label}`;

  toast(title, {
    id: event.id,
    description: daysFromNow(new Date(event.actionAt).toISOString()),
    duration: 10000,
    cardProps: {
      color: `${event.actionState === 'COMPLETE' ? 'success' : 'primary'}`,
      density: 'compact'

    },
    action: {
      label: 'Dismiss',
      onClick: () => toast.dismiss(event.id),
      buttonProps: {
        color: 'white',
        variant: 'outlined',
        size: 'small',
        elevation: 2
      }
    },
    important: false,
    onAutoClose: () => { },
    onDismiss: () => { }
  });
}

const editedTasDetails = async (updateInput: UpdateTaskInput) => {
  try {
    loading.value = true;
    await editTaskDetail!(updateInput);
    if (!isPatientProfileTaskView) {
      await queryClient.invalidateQueries({ queryKey: ['taskBoard'] });
    }
    await queryClient.invalidateQueries({ queryKey: ['myTasks'] });

    loading.value = false;
    showEditSuccessMessage.value = true
    emits('taskEditSuccess');
  }
  catch (error) {
    loading.value = false;
    const err = error as Error;
    errorMessage.value = err.message;
    showErrorMessage.value = true;
  }
}

watch(() => props.task, (newTask) => {
  task.value = newTask;
  const selectedTaskQueueId = newTask.currentQueue?.taskQueueId;
  const getSelectedTaskQueue = taskQueues!.find(taskQueue => taskQueue.value.id === selectedTaskQueueId);
  selectedTaskQueue.value = getSelectedTaskQueue; selectedStatusState.value = newTask.status;
  getDueDays(newTask.dueDate)
})


const getDueDays = (dueDates: string | undefined) => {
  dueDate.value = dueDates ? daysFromNow(dueDates) : undefined;
}


onMounted(async () => {
  getDueDays(task.value.dueDate)
  selectedStatusState.value = task.value.status;
  const selectedTaskQueueId = task.value.currentQueue?.taskQueueId;
  const getSelectedTaskQueue = taskQueues!.find(taskQueue => taskQueue.value.id === selectedTaskQueueId);
  selectedTaskQueue.value = getSelectedTaskQueue;

  taskActivityEventBus.on((e) => {
    if (task.value.id === e.task.id) {
      if (isCurrentUser(e.actor)) {
        currentUserActionEvent.value = e;
        const actionState = currentUserActionEvent.value.actionState;
        if (actionState === 'PAUSED') {
          isActivityTimePausedExplicity.value = true;
        } else if (actionState === 'RESUMED') {
          isActivityTimePausedExplicity.value = false;
        }
        taskActionFromTaskPopupEventBus.emit(e);
        currentUserActionState(actionState, e);
        patientTaskTrackerEventBus.emit({
          patientId,
          activity: e,
          taskActivityTimerReading: actionTimeElapsed.value.timerReading
        });
      } else {
        otherUsersActionEvents.value = [...otherUsersActionEvents.value, e];
        const concurrentEvents = otherUsersActionEvents.value;
        const concurrentEventsCount = concurrentEvents.length;
        if (concurrentEventsCount > maxConcurrentToasts) {
          forEach(concurrentEvents.slice(0, concurrentEventsCount - maxConcurrentToasts), (e) => {
            return toast.dismiss(e.id);
          });
        }
        displayOtherUserActivityToast(e);

      }
    }
  });

  taskTimerControlEventBus.on(e => {
    const timeToRecord = e.timeToRecord ?? 0;
    if (e.activity && e.activity.task && e.activity.task.id === task.value.id && e.activity.actor.userId === currentUser.userId) {
      if (taskActionStopwatch.isRunning.value) {
        taskActionStopwatch.pause();
      }
      const stopWatchReading = actionTimeElapsed.value.elapsedTime.asMilliseconds();
      const resetToTime = ((stopWatchReading + timeToRecord)) / 1000;
      if (!isActivityTimePausedExplicity.value) {
        taskActionStopwatch.reset(resetToTime);
      }

      if (e.timerState.timerStatus === AppActivityTrackerState.PAUSED &&
        taskActionStopwatch.isRunning.value) {
        taskActionStopwatch.pause();
      } else if (e.timerState.timerStatus === AppActivityTrackerState.RUNNING && !isActivityTimePausedExplicity.value) {
        taskActionStopwatch.reset(resetToTime);
      }
    }
  });
});

</script>

<script lang="ts">
export default {
  name: 'PatientTaskDetails'
}
</script>

<style scoped lang="scss">
@import '../../../../assets/styles/vt-custom-style-variables.scss';


.task-details-card {
  height: 600px;
}

.task-details-context>.task-details-title-chip {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.task-details-action {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.task-details-title-chip {
  margin: 4px;
}

.task-details-title {
  position: relative;
  max-width: 600px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task-title {
  display: inline;
}
</style>
