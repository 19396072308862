<template>
  <v-card class="h-100 mx-2" color="surfContainer" elevation="2">
    <v-card-item class="bg-surface">
      <template v-slot:title>
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-account-wrench-outline</v-icon>
          <div class="w-50 d-flex flex-wrap">
            <span class="ml-4"> Service Providers </span>
            <span class="ml-4 v-card-subtitle flex-1-1-100">Add and manage Service Providers for your
              organization</span>
          </div>
          <v-spacer />
          <v-dialog v-model="dialogVisible" persistent width="900px">
            <template v-slot:activator="{ props }">
              <div class="selectionContainer">
                <v-btn color="primary" variant="elevated" elevation="3" :loading="loading" v-bind="props" rounded="false"
                  @click="getServiceProviders"><v-icon size="large">mdi-plus</v-icon>
                  <span>Add Service Provider</span></v-btn>
              </div>
            </template>
            <template v-slot:default>
              <v-card class="mx-auto my-auto" max-width="650" flat>
                <div>
                  <OrgServiceProviderConfigureForm v-if="showServiceProvidersForm"
                    :serviceProviderOptions="serviceProviderOptions" :loading="loading"
                    @configure-service-provider="providerConfiguration" @close="dialogVisible = false" />
                  <component :is="serviceProviderConfigForm" :loading="loading" @close="dialogVisible = false"
                    @show-configure-service-provider-form="configureServiceProvider"
                    :organization-service-provider-id="organizationServiceProviderId"
                    :edit-data="orgServiceProviderData" />
                </div>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </template>
    </v-card-item>
    <v-divider thickness="1" class="my-2" />
    <v-skeleton-loader v-if="loading" color="surface"
      type="table"></v-skeleton-loader>
    <v-card-text v-else>
      <v-container fluid class="py-0">
        <v-row no-gutters class="py-0">
          <v-col>
            <OrganizationServiceProviders :organizationServiceProviders="organizationServiceProviders"
              @edit-org-service-provider="editOrgServiceProviderDetails" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-snackbar color="green" class="text-white mt-16" v-model="serviceProviderConfigureSuccess" location="top right">
      {{ configureSuccessMessage }}
            <template v-slot:actions>
        <v-icon class="ml-3" @click="serviceProviderConfigureSuccess = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" class="text-white mt-16" v-model="serviceProviderConfigureError" location="top right">
      {{ errorMessage }}
            <template v-slot:actions>
        <v-icon class="ml-3" @click="serviceProviderConfigureError = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import { inject, onMounted, ref, shallowRef } from "vue";
import { ApolloClient } from "@apollo/client/core";
import { useRoute } from "vue-router";
import {
  IOrgServiceProvider, IOrganizationServiceProvider,
} from "@/interfaces/IOrganization";
import { GET_SERVICE_PROVIDERS } from "../../graphql/resources/ServiceProviderRepository";
import {
  GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID,
  UPSERT_ORG_SERVICE_PROVIDER,
  GET_ORG_SERVICE_PROVIDER_BY_ID,
} from "../../graphql/resources/OrganizationServiceProviderRepository";
import OrgServiceProviderConfigureForm from "./OrgServiceProviderConfigureForm.vue";
import OrganizationServiceProviders from "./OrganizationServiceProviders.vue";
import { extractErrorMessage } from "@/composables/FormUtility";
import { assign, map } from "lodash";
import { ServiceProviderOption } from "@/interfaces/IOrganization";
import { getServiceProviderConfigForm } from "@/composables/serviceprovider.composable";
import { SAVE_ORG_SOURCE_DEFINITION } from "@/graphql/resources/OrgSourceDefination";


const apolloClient = inject("apolloClient") as ApolloClient<any>;
const serviceProviderOptions = ref<ServiceProviderOption[]>([]);
const dialogVisible = ref(false);
const route = useRoute();
const orgId = ref(route.params["orgId"] as string);
const serviceProviderConfigureSuccess = ref(false);
const serviceProviderConfigureError = ref(false);
const errorMessage = ref("");
const loading = ref(false);
const organizationServiceProviders = ref([] as IOrgServiceProvider[]);
const serviceProviderConfigForm = shallowRef();
const showServiceProvidersForm = ref(false);
const configureServiceProviderId = ref("");
const orgServiceProviderData = ref<IOrganizationServiceProvider>({
  serviceProviderId: "",
  configuration: {} as JSON,
});

const organizationServiceProviderId = ref("");
const configureSuccessMessage = ref("");

const providerConfiguration = (selectedServiceProvider: { shortCode: string; serviceProviderId: string; }) => {
  const { shortCode, serviceProviderId } = selectedServiceProvider;
  serviceProviderConfigForm.value = getServiceProviderConfigForm(shortCode);
  configureServiceProviderId.value = serviceProviderId;
  orgServiceProviderData.value.configuration = {} as JSON;
}

const providersFormVisible = () => {
  dialogVisible.value = true;
  organizationServiceProviderId.value = '';
  showServiceProvidersForm.value = true;
  serviceProviderConfigForm.value = null;
};

const getServiceProviders = async () => {
  providersFormVisible();
  try {
    const result = await apolloClient.query({
      query: GET_SERVICE_PROVIDERS,
      variables: {
        limit: 0,
        offset: 0,
      },
    });
    const doesServiceProviderExist = map(
      organizationServiceProviders.value,
      "serviceProviderId"
    );
    const serviceProviders = result.data.serviceProviders.serviceProviders;
    const serviceProviderOptionsValue = map(
      serviceProviders,
      (serviceProvider) => ({
        title: serviceProvider.serviceProviderName,
        value: serviceProvider.serviceProviderId,
        disabled: doesServiceProviderExist.includes(serviceProvider.serviceProviderId),
        shortCode: serviceProvider.shortCode,
      })
    );
    serviceProviderOptions.value = serviceProviderOptionsValue;
  } catch (error) {
    errorMessage.value = error as string;
    serviceProviderConfigureError.value = true;
  }
};

const editOrgServiceProviderDetails = async (orgServiceProviderDetails: IOrgServiceProvider) => {
  await getOrgServiceProviderById(orgServiceProviderDetails.orgServiceProviderId);
  dialogVisible.value = true;

  const { shortCode } = orgServiceProviderDetails;
  serviceProviderConfigForm.value = getServiceProviderConfigForm(shortCode);

  showServiceProvidersForm.value = false;
};


const configureServiceProvider = async (serviceProviderDetails: JSON) => {
  loading.value = true;
  orgServiceProviderData.value = {
    serviceProviderId: configureServiceProviderId.value,
    configuration: serviceProviderDetails,
  };

  try {
    const response = await apolloClient.mutate({
      mutation: UPSERT_ORG_SERVICE_PROVIDER,
      variables: {
        input: orgServiceProviderData.value,
      },
    });

    await apolloClient.mutate({
      mutation: SAVE_ORG_SOURCE_DEFINITION,
      variables: {
        serviceProviderId: orgServiceProviderData.value.serviceProviderId,
      }
    })
    const { data: { orgServiceProviders: { upsertOrgServiceProvider } } } = response;
    loading.value = false;
    dialogVisible.value = false;
    serviceProviderConfigureSuccess.value = true;

    const existingProvider = organizationServiceProviders.value.find(
      (provider) => provider.orgServiceProviderId === organizationServiceProviderId.value
    );

    if (existingProvider) {
      assign(existingProvider, {
        ...upsertOrgServiceProvider,
        sourceTypes: upsertOrgServiceProvider.sourceTypes.join(", "),
      });
      configureSuccessMessage.value = "Service provider configuration updated successfully";
    } else {
      organizationServiceProviders.value.push({
        ...upsertOrgServiceProvider,
        sourceTypes: upsertOrgServiceProvider.sourceTypes.join(", "),
      });
      configureSuccessMessage.value = "Service provider configured successfully";
      getOrgServiceProvidersByOrgId();
    }
  } catch (error) {
    loading.value = false;
    const { message } = error as Error;
    errorMessage.value = extractErrorMessage(message);
    serviceProviderConfigureError.value = true;
  }
};

const getOrgServiceProvidersByOrgId = async () => {
  loading.value = true;
  await apolloClient
    .query({
      query: GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID,
      variables: {
        orgId: orgId.value,
      },
      fetchPolicy: 'network-only'
    })
    .then((res) => {
      const { data: { orgServiceProviders: { listOrgServiceProvidersByOrgId } } } = res;
      organizationServiceProviders.value = listOrgServiceProvidersByOrgId.map(
        ({
          orgServiceProviderId,
          isActive,
          serviceProviderName,
          sourceTypes,
          configuration,
          serviceProviderId,
          shortCode,
        }: IOrgServiceProvider) => ({
          orgServiceProviderId,
          isActive,
          serviceProviderName,
          sourceTypes: sourceTypes.join(", "),
          configuration,
          serviceProviderId,
          shortCode
        })
      );
      loading.value = false;
    })
    .catch((err) => {
      loading.value = false;
      errorMessage.value = extractErrorMessage(err.message);
      serviceProviderConfigureError.value = true;
    });
};

const getOrgServiceProviderById = async (selectedOrgServiceProviderId: string) => {
  try {
    const data = await apolloClient.query({
      query: GET_ORG_SERVICE_PROVIDER_BY_ID,
      variables: {
        orgServiceProviderId: selectedOrgServiceProviderId,
      },
      fetchPolicy: 'no-cache'
    });
    const { data: { orgServiceProviders: { orgServiceProvider } } } = data;
    const {
      orgServiceProviderId,
      serviceProviderId,
      configuration,
    } = orgServiceProvider;
    organizationServiceProviderId.value = orgServiceProviderId;
    orgServiceProviderData.value.configuration = configuration;
    configureServiceProviderId.value = serviceProviderId;
  } catch (error) {
    const { message } = error as Error;
    errorMessage.value = extractErrorMessage(message);
    serviceProviderConfigureError.value = true;
  }
};

onMounted(() => {
  getOrgServiceProvidersByOrgId();
});
</script>

<style scoped>
.selectionContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
