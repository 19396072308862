<template>
  <v-card class="w-100">
    <v-card-title class="text-primary">Organization Invoice Creation</v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="8">
          <v-text-field variant="outlined" v-model="previousMonth" class="mt-3" label="Previous Month"
            readonly></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions class="d-flex justify-end pt-10">
        <v-btn variant="tonal" elevation="3" :rounded="false" @click="$emit('close')" >Cancel</v-btn>
        <v-btn variant="flat" :loading="loading" elevation="3" :rounded="false" @click="sendInvoiceEmail">Send Invoice
          Mail</v-btn>
      </v-card-actions>
    </v-card-item>
  </v-card>
  <v-snackbar color="success" v-model="showSuccessMessage" class="mt-16" location="top right">{{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" v-model="showErrorMessage" class="mt-16" location="top right">{{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { IInvoiceOrgInfo } from '@/interfaces/IOrganization';
import { PropType, ref } from 'vue';
import { generateInvoice } from '@/services/organization.service';
import moment from 'moment';

const props = defineProps({
  invoiceOrgInfo: {
    type: Object as PropType<IInvoiceOrgInfo>,
    required: true,
  },
});

const emits = defineEmits(['close'])

const showSuccessMessage = ref(false);
const successMessage = ref('');
const showErrorMessage = ref(false);
const errorMessage = ref('');
const loading = ref(false);

const previousMonth = ref(moment().subtract(1, 'months').format('MMMM YYYY'));


const sendInvoiceEmail = async () => {
  const startDate = moment().subtract(1, 'months').startOf('month').toISOString();
  const endDate = moment().subtract(1, 'months').endOf('month').toISOString();
  const input = {
    ...props.invoiceOrgInfo,
    startDate: new Date(startDate),
    endDate: new Date(endDate)
  }
  loading.value = true;
  await generateInvoice(input).then(() => {
    showSuccessMessage.value = true;
    successMessage.value = 'Invoice mail send successfully'
  }).catch((error: Error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  }).finally(() => {
    loading.value = false
  })
}
</script>
