import { CHECK_NPI_EXITS_ORG } from "@/graphql/resources/OrganizationRepository";
import { apolloClient } from "../plugins/graphqlclient";
import { INVOICE_GENERATION } from "@/graphql/resources/organization/Organization";
import { InvoiceInput } from "@/interfaces/IOrganization";

export const doesOrgNpiExist = async (npi: string): Promise<boolean> => {
  const response = await apolloClient.query({
    query: CHECK_NPI_EXITS_ORG,
    variables: {
      npi,
    },
    fetchPolicy: "network-only",
  });
  const { checkNPIExist } = response.data;
  return checkNPIExist;
};

export const generateInvoice = async (input: InvoiceInput) => {
  const response = await apolloClient.mutate({
    mutation: INVOICE_GENERATION,
    variables: {
      input,
    },
  });

  return response.data.buildInvoiceInfoAndSendMail;
};
